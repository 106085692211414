import React, { useEffect, useState } from "react";

const ReturnDetails = ({returnInfo, setReturnInfo}) => {
  const [selectedCarrier, setSelectedCarrier] = useState('');
  const [trackingCode, setTrackingCode] = useState('');
  const [message, setMessage] = useState('');
  const [trackingCodeError, setTrackingCodeError] = useState(null);

  const carriers = [
    { carrier_id: 1, name: 'UPS', tracking_code_regex: '^1z[0-9a-z]{6}[0-9]{2}[0-9a-z]{8}$' },
    { carrier_id: 2, name: 'USPS', tracking_code_regex: '^\\d{4}\\s?\\d{4}\\s?\\d{4}\\s?\\d{4}\\s?\\d{4}(\\s?\\d{1,2})?$' },
    { carrier_id: 3, name: 'PosLaju', tracking_code_regex: null },
    { carrier_id: 4, name: 'Pos Ekspress', tracking_code_regex: null },
    { carrier_id: 5, name: 'Pos Parcel', tracking_code_regex: null },
    { carrier_id: 6, name: 'Fedex', tracking_code_regex: '^\\d{4}\\s?\\d{4}\\s?\\d{4}(\\s?\\d{3})?$' },
    { carrier_id: 7, name: 'DHL', tracking_code_regex: '^\\d{10}$' },
    { carrier_id: 8, name: 'SKYNET', tracking_code_regex: '^\\d{12}$|laz(w|m)\\d{10}$' },
    { carrier_id: 9, name: 'TNT Express', tracking_code_regex: '^(gd|ge)?\\d{9}(ww)?$' },
    { carrier_id: 10, name: 'Singapore Post', tracking_code_regex: null },
    { carrier_id: 11, name: 'SKYNET Australia', tracking_code_regex: null },
    { carrier_id: 12, name: 'TOLL', tracking_code_regex: null },
    { carrier_id: 100, name: 'Others (Please mention carrier name in comments)', tracking_code_regex: null },
    { carrier_id: 13, name: 'Royal Mail', tracking_code_regex: '^([a-z]{2})\\d{9}(gb)$' },
    { carrier_id: 14, name: 'Australia Post', tracking_code_regex: '^([a-z]{2}\\s?)\\d{9}\\s?(au)$' },
    { carrier_id: 15, name: 'Canada Post', tracking_code_regex: '^(\\d{4}\\s?\\d{4}\\s?\\d{4}\\s?\\d{4}$)|([a-z]{2}\\s?\\d{3}\\s?\\d{3}\\s?\\d{3}\\s?[a-z]{2}$)' },
    { carrier_id: 104, name: 'NTL', tracking_code_regex: '^\\d{10}$' },
    { carrier_id: 105, name: 'TCS', tracking_code_regex: null },
  ];

  const handleCarrierChange = (event) => {
    setSelectedCarrier(event.target.value);
    setTrackingCode(''); 
    setTrackingCodeError(null); 
  };

  const handleTrackingCodeChange = (event) => {
    const code = event.target.value;
    setTrackingCode(code);

    const selectedCarrierInfo = carriers.find(
      (carrier) => carrier.carrier_id === parseInt(selectedCarrier)
    );

    if (selectedCarrierInfo && selectedCarrierInfo.tracking_code_regex) {
      const regex = new RegExp(selectedCarrierInfo.tracking_code_regex);
      let codeI = code.toLowerCase();
      if (!regex.test(codeI)) {
        setTrackingCodeError('Invalid tracking code for selected carrier');
      } else {
        setTrackingCodeError(null);
      }
    } else {
      setTrackingCodeError(null); 
    }
  };


  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    fetch("/returns/update_refund_details", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      body: JSON.stringify({
        carrier: selectedCarrier,
        tracking_code: trackingCode,
        message: message,
        ids: returnInfo.data.ids,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setReturnInfo({state: "success", data: returnInfo.data });
        } else {
          alert("Failed to update refund option");
        }
      });
  }

  return (
    <form onSubmit={handleSubmit} className="carrier-select-form">
    <div className="row">
      <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 mtl text-left">
        <label htmlFor="carrier">Carrier</label>
        <select
          name="carrier"
          id="carrier"
          className="form-control"
          required
          value={selectedCarrier}
          onChange={handleCarrierChange}
        >
          <option value="" disabled>-- please select a carrier --</option>
          {carriers.map((carrier) => (
            <option
              key={carrier.carrier_id}
              value={carrier.carrier_id}
              data-tracking-regex={carrier.tracking_code_regex}
            >
              {carrier.name}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 mtl text-left">
        <label htmlFor="tracking_code" className="mt-3">Tracking Code</label>
        <input
          required
          type="text"
          className="form-control"
          name="tracking_code"
          id="tracking_code"
          placeholder="Enter tracking code for return shipment"
          value={trackingCode}
          onChange={handleTrackingCodeChange}
        />
      </div>

      {trackingCodeError && (
              <div className="error-message" style={{ color: 'red', marginTop: '5px' }}>
                {trackingCodeError}
              </div>
      )}

      <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 mbh mt-3">
        <textarea
          required
          name="message"
          className="form-control"
          rows="3"
          placeholder="Your feedback will help us improve"
          value={message}
          onChange={handleMessageChange}
        />
      </div>

      <div className="form-group col-lg-3 col-md-3 col-sm-3 col-xs-12 mbh mt-3">
        <button
          type="submit"
          className="cart-btn btn btn-default"
          disabled={trackingCodeError || !selectedCarrier || !trackingCode}
        >
          Submit
        </button>
      </div>
      
    </div>
    </form>
  );
};

export default ReturnDetails;