import React, { useEffect, useState } from "react";
import ReturnDetails from './ReturnDetails';

const reasonsToMessage = {
  1: (
    <>
      <p>
        We noticed that our product didn't fit you—major bummer, right? Equally
        for us, because we try our best to make shopping with us a total breeze,
        from offering a virtual fitting room to size assistance. Nothing makes
        us happier than a happy customer.
      </p>
      <p>
        Rest assured that we're here to make things right. Let's find a
        worthwhile solution, here are a few choices
      </p>
    </>
  ),
  4: (
    <>
      <p>
        Thank you for reaching out to request a refund on your purchase. We
        appreciate your feedback and are sorry you did not receive the exact
        product you were expecting.
      </p>

      <p>
        We want you to know that the leather we use in our products is
        all-natural, and because of this, there may be subtle variations in the
        final product that we can't always control. Additionally, while we
        strive to provide high-quality images, we try our best to avoid
        excessive editing to truly represent the product in the most honest way
        possible.
      </p>

      <p>
        We take your concerns seriously and would love to find a way to make
        things better. Let's find a worthwhile solution, here are a few choices
      </p>
    </>
  ),
  5: (
    <>
      <p>
        Thank you for reaching out to request a refund on your purchase. We
        understand that sometimes our product might not meet your expectations.
        As a small business, we face significant challenges with refunds,
        including potential net losses, increased efforts for handling and
        restocking, and even product wastage.
      </p>
      <p>
        By choosing to exchange your product, you not only help us minimize
        these challenges but also allow us to better serve you and improve our
        offerings. Rest assured that we're here to make things right. Let's find
        a worthwhile solution, here are a few choices
      </p>
    </>
  ),
  9: (
    <>
      <p>
        We are so sorry to hear that it was defective. Thank you for bringing
        this to our attention because we genuinely want the best experience for
        our customers. We take quality control very seriously and are
        investigating what went wrong.
      </p>
      <p>
        Rest assured that we're here to make things right. Let's find a
        worthwhile solution, here are a few choices
      </p>
    </>
  ),
  10: (
    <>
      <p>
        We are sorry that the product you received didn't meet your
        expectations. Thank you for bringing this to our attention because we
        genuinely want the best experience for our customers. We take quality
        control very seriously and are investigating what went wrong.
      </p>
      <p>
        Rest assured that we're here to make things right. Let's find a
        worthwhile solution, here are a few choices
      </p>
    </>
  ),
  11: (
    <>
      <p>
        Thank you for reaching out to request a refund on your purchase. We
        understand that sometimes our delivery time might not meet your
        expectations. As a small business, we face significant challenges with
        refunds, including potential net losses, increased efforts for handling
        and restocking, and even product wastage.
      </p>
      <p>
        By choosing to exchange your product, you not only help us minimize
        these challenges but also allow us to better serve you and improve our
        offerings. Rest assured that we're here to make things right. Let's find
        a worthwhile solution, here are a few choices
      </p>
    </>
  ),
};

export default function ReturnSubmission({ orderId, region, mtmFee, existingReturnId, existingReturnState, existingReturnType }) {
  const [returnInfo, setReturnInfo] = useState(() => {

    let data = {orderId: orderId, ids: []};
    let state = null;

    if (existingReturnState == 'PRODUCT_SELECTION_MADE') {
      if (existingReturnType == 2) {
        state = 'refund-confirm'
      } else {
        state = 'return-details'
      }
    } else if (existingReturnState == 'REFUND_SELECTION_MADE') {
      state = 'return-details'
    }

    
    if (existingReturnId > 0) {
      data.ids = [existingReturnId];
      data.return_number = existingReturnId;
    }
    
    return { data: data, state: state };
  });

  function showReturnSuccess(e, d) {
    if (d.success) {
      $("html, body").animate({ scrollTop: 0 }, "slow");

      $("[data-return-form]").addClass("display-none");
      $("[return-validation-error]").addClass("display-none");

      setReturnInfo({
        state: d.state == 'SUBMITTED' ? 'success' : d.type === 2 ? "refund-confirm" : "return-details",
        data: d,
      });
    } else {
      // in jQuery return.js
      window.showReturnFailure(e, d);
    }
  }

  function handleRefundChange(option) {
    // call API to update refund option
    fetch("/returns/update_refund_option", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      body: JSON.stringify({
        swap: option.text,
        type: option.type,
        refund_action: option.value,
        ids: returnInfo.data.ids,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setReturnInfo({state: data.state == "SUBMITTED" ? "success" : "return-details", data: returnInfo.data });
        } else {
          alert("Failed to update refund option");
        }
      });
  }

  function getRefundAmount() {
    return "15%"
  }

  function getRefundAmountOnProductKeep() {
   return "50%"
  }

  useEffect(() => {
    $("#return-form").on("ajax:success", showReturnSuccess);

    return () => {
      $("#return-form").off("ajax:success", showReturnSuccess);
    };
  }, []);

  const options = [
    {
      text: `Keep the product & get ${getRefundAmountOnProductKeep()} refund`,
      value: "keep_item",
      type: 5,
    },
    { 
      text: `Get exchange + ${getRefundAmount()} refund`,
      value: "exchange",
      type: 1,
    },
    { 
      text: "Get a full refund", 
      value: "full_refund",
      type: 2,
    },
  ];

  if (returnInfo.state === null) {
    return null;
  }

  if (returnInfo.state === "refund-confirm") {
    return (
      <div className="row text-center order-verification" style={{ padding: "10% 0" }}>
        <div className="col-sm-offset-2 col-sm-8 col-xs-12 mbh">
          <h3 className="mbm">We're Here to Help!</h3>
          <div className="font-20 hidden-md hidden-sm hidden-xs">
            {reasonsToMessage[returnInfo.data.reason]
              ? reasonsToMessage[returnInfo.data.reason]
              : reasonsToMessage[5]}
          </div>
          <div className="font-16 hidden-lg">
            {reasonsToMessage[returnInfo.data.reason]
              ? reasonsToMessage[returnInfo.data.reason]
              : reasonsToMessage[5]}
          </div>
        </div>
        <div className="col-sm-12 col-xs-12 mbl">
          {options.map((option, index) => (
            <a
              key={index}
              onClick={() => handleRefundChange(option)}
              className="form-btn-dark phl"
            >
              {option.text}
            </a>
          ))}
        </div>

        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 block-padding text-center mth">
          <p><b>Have questions?</b></p>
          <p><b>TheJacketMaker Team is happy to help!</b></p>
          <p><a href="/contact-us">Contact Us</a></p>
        </div>
      </div>
    );
  }

  if (returnInfo.state === "success") {
    return (
      <div className="col-lg-8 col-md-8 col-sm-8 col-sm-offset-2 col-xs-12 return-success">
        <div className="content-head text-center">
          <h2>RETURNS</h2>
          <h3>ORDER # {orderId}</h3>
        </div>
        <div className="row text-center">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12"></div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{marginTop: '25px', marginBottom: '25px'}}>
            <i className="fa fa-check fa-2x" aria-hidden="true"></i>
            <br />
            <p>
              Your return number is{" "}
              <strong>{returnInfo.data.return_number}</strong>
            </p>
            <p>
              You'll recieve a confirmation email shortly on{" "}
              <span>{returnInfo.data.email}</span>
            </p>
            <p>
              In case of any question you can Contact us at :{" "}
              <a href="<%= linkt'mailto:contact@thejacketmaker.com'%>">
                contact@thejacketmaker.com
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }

  if (returnInfo.state === "return-details") {
    return (
      <div className="col-lg-8 col-md-8 col-sm-8 col-sm-offset-2 col-xs-12 return-success">
        <div className="content-head text-center">
          <h2>RETURNS</h2>
          <strong>ORDER # {orderId}</strong>
        </div>

        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <h4><b>Return Instructions</b></h4>
          <p className="mtm">Please follow the instructions below within 7 days.</p>
          <ol>
            <li>Pack the item(s) securely in the original product packaging. All ordered and complimentary items must be returned in good condition along with the packing slip. In case of shoe returns, the item should be unworn, unwrinkled, and undamaged. In case the item(s) is received in damaged condition, please contact the customer support team here.</li>
            <li>Send the return package to the following address, please include your order details slip with the item(s).
              <p>The Jacket Maker,</p>
              <p>5 Parkwood DR APT I,</p>
              <p>South Amboy, NJ 08879-2310,</p>
              <p>United States</p>
            </li>
            <li>A tracking number will be received from the courier on returning the parcel. Please fill the below form with the returned parcel tracking number to complete the return.</li>
          </ol>
          <p>P.S. We've also emailed you a link to this page, allowing you to complete the return within the next 7 days.</p>
          <p><a href="/help#refunds-returns">View Black Friday Return Policies</a></p>
          </div>
        </div>

        
        <div className="row text-center">
          <ReturnDetails returnInfo={returnInfo} setReturnInfo={setReturnInfo}/>
        </div>

        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 block-padding text-center mth">
          <p><b>Have questions?</b></p>
          <p><b>TheJacketMaker Team is happy to help!</b></p>
          <p><a href="/contact-us">Contact Us</a></p>
        </div>
      </div>
    );
  }

  return null;
}